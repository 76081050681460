import React from 'react'
import { Alert, Button } from 'react-bootstrap'
import { unauthNavTo } from '../../components/navigation'
import Spinner from '../../components/spinner'
import {
  useCheckInviteCompanyName,
  useSetAcceptParticipantInvite
} from '../../utils/api'
const AcceptInvitation = (props) => {
  const { token } = props

  const queryParams = new URLSearchParams(window.location.search)
  const newInvitee = queryParams.get('company')

  const checkInviteCompanyName = useCheckInviteCompanyName()
  const setAcceptParticipantInvite = useSetAcceptParticipantInvite()

  React.useEffect(() => {
    if (checkInviteCompanyName.isSuccess) {
      console.log({ checkInviteCompanyName })
    }
  })

  React.useEffect(() => {
    let timer
    if (setAcceptParticipantInvite.isSuccess) {
      console.log({ setAcceptParticipantInvite })
      timer = setTimeout(() => unauthNavTo.dashboardPage(), 2000)
    }
    return () => {
      clearTimeout(timer)
    }
  })
  // functions

  const acceptInvitation = () => {
    setAcceptParticipantInvite.mutate({ token: token })
  }
  return (
    <div className="px-4 px-md-5 d-flex flex-column flex-grow-1 my-8">
      {/* <h5 className="d-flex justify-content-end my-0">1/4</h5> */}
      <div className="d-flex justify-content-between">
        <h4 className="mb-3 font-weight-bold">You’ve been invited</h4>
      </div>
      <p><b>{newInvitee} </b>has invite you to join them on Reflow Hub</p>
      <Button className="mt-6" onClick={acceptInvitation} diable={setAcceptParticipantInvite.isSuccess}>
        {setAcceptParticipantInvite.isLoading
          ? (
          <Spinner />
            )
          : (
          <span >Accept Invitation</span>
            )}
      </Button>
      {setAcceptParticipantInvite.isSuccess && (
        <Alert variant="success" className="mb-0 mt-3 text-center">
          successful!
        </Alert>
      )}
    </div>
  )
}

export default AcceptInvitation
